<template>
    <div @contextmenu.prevent class="main">
      <!-- 修改资料 -->
      <el-dialog
        :visible.sync="dialogVisible"
        width="30%">
        <div style="margin-top: -40px;font-size: 16px;font-weight: 550;color: #BCBCBC;">修改资料</div>
        <div style="display: flex;margin-top: 20px;">
          <div style="font-size: 14px;color: #BCBCBC;margin-left: 30px;margin-right: 15px;margin-top: 10px;">头像:</div>
          <div @click="choose_img" style="width: 106px;height: 106px;display: flex;justify-content: center;align-items: center;background-color: rgba(243, 243, 243,0.2);border-radius: 60px;cursor: pointer;">
            <input type="file" style="display: none" ref="imgInput" @change="load_tx">
            <el-avatar :size="100" shape='circle' :src="edit_user.pic">头像</el-avatar>
          </div>
        </div>
        <div style="display: flex;margin-top: 30px;align-items: center;">
          <div style="font-size: 14px;color: #BCBCBC;margin-left: 30px;margin-right: 15px;">昵称:</div>
          <div style="height: 35px;width: 300px;display: flex;align-items: center;background-color: rgba(32, 32, 32,0.9);border-radius: 5px;justify-content: center;">
            <input class="input" type="text" placeholder="取个好听的昵称" v-model="edit_user.nickname"  @keyup.enter="xiugai">
          </div>
        </div>
        <div style="display: flex;align-items: center;justify-content: flex-end;margin-top: 40px;">
          <div @click="xiugai" style="display: flex;align-items: center;justify-content: center;width:80px;height: 35px;background-color: #2A3A70;border-radius: 6px;color: #C4C4C4;cursor: pointer;">确认</div>
        </div>
      </el-dialog>
      <!-- 修改资料 -->
     <div class="index">
      <div style="color: #C7C7C7;font-size: 15px;margin-bottom: 20px;">琼ICP备2022000809号-1</div>
     </div>
     <div @contextmenu.prevent class="window">
       <div class="window2">
        <div style="flex: 1;height: 98%;overflow: hidden;">
          <el-tooltip class="item" effect="dark" content="退出登录" placement="top-start">
            <div @click="logup"  style="width: 15px;height: 15px;background-color: rgba(249, 106, 88,0.7);border-radius: 30px;margin-left: 15px;margin-top: 5px;cursor: pointer;"></div>
          </el-tooltip>
          <div style="margin-top: 15px;width: 100%;display: flex;flex-direction: column;align-items: center;">
            <div @click="user_edit" style="width: 66px;height: 66px;display: flex;justify-content: center;align-items: center;background-color: rgba(243, 243, 243,0.2);border-radius: 60px;cursor: pointer;">
              <el-avatar :size="60" shape='circle' :src="userimg">头像</el-avatar>
            </div>
            <div @click="user_edit" style="color: #EBEBEB;font-size: 16px;margin-top: 10px;display: flex;align-items: center;">
              <div style="cursor: pointer;">{{ nickname }}</div>
              <img src="../assets/man.png" style="width: 20px;height: 20px;margin-left: 2px;">
            </div>
            <!-- <div style="color: #A2A2A2;margin-top: 10px;font-size: 13px;">uid:{{ username }}</div> -->
          </div>
          <!-- <div style="display: flex;align-items: center;justify-content: center;margin-top: 30px;">
            <div style="flex: 1;display: flex;align-items: center;justify-content: flex-start;margin-left: 20px;font-size: 14px;color: #EBEBEB;">账号权限：</div>
            <div style="flex: 1.5;display: flex;align-items: center;justify-content: flex-end;margin-right: 20px;">
              <div style="display: flex;justify-content: center;align-items: center;background-color: rgba(243, 243, 243,0.1);border-radius: 20px;">
                <img src="../assets/hy.png" style="width: 18px;height: 18px;margin-left: 5px;margin-top: 3px;margin-bottom: 3px;">
                <div style="margin-left: 3px;font-size: 12px;color: #DFDFDF;margin-right: 10px;">管理员</div>
              </div>
            </div>
          </div> -->
          <!-- <div style="display: flex;align-items: flex-end;width: 100%;justify-content: center;height: 100%;">
            <div style="margin-bottom: 200px;">
              <div @click="logup" class="logup">退出登录</div>
            </div>
          </div> -->
        </div>
        <div class="chat">
          <div style="width: 100%;height: 40px;background-color: #5B5E69;display: flex;justify-content: center;align-items: center;color: #EBEBEB;font-weight: 550;font-size: 14px;flex: 0.6;">
            RChat官方交流群
          </div>
          <div class="chat_list" id="chat-content" @scroll="handleScroll" :style="{'scroll-behavior':scrollbehavior}">
            <div style="width: 100%;display: flex;justify-content: center;">
              <div v-if="is_msg_loading" style="display: flex;align-items: center;margin-top: 20px;margin-bottom: 10px;">
                <i style="color: #EDEDED;" class="el-icon-loading"></i>
                <div style="color: #EDEDED;font-size: 13px;margin-left: 5px;">消息加载中</div>
              </div>
            </div>
            <div v-for="(item,index) in chat_list" :key="index">
              <!-- 聊天消息 -->
               <div v-if="item.status=='time_Records'" style="width: 100%;display: flex;justify-content: center;margin-top: 20px;margin-bottom: 10px;">
                  <div style="color: #979797;font-size: 13px;">{{ item.msg.send_time }}</div>
               </div>
               <div v-if="item.msg.type=='3'" style="width: 100%;display: flex;justify-content: center;margin-top: 20px;margin-bottom: 10px;">
                  <div style="color: #979797;font-size: 14px;">{{ item.msg.text }}</div>
               </div>
              <!-- 聊天气泡-自己 -->
              <div v-if="item.user_id==user_id&&item.msg.type!='3'" style="width: 100%;display: flex;justify-content: flex-end;margin-bottom: 20px;" :style="{'margin-top':index==0?'20px':'0'}">
                <div style="margin-right: 20px;display: flex;">
                  <div style="display: flex;justify-items: center;flex-direction: column;margin-right: 10px;">
                    <div style="margin-top: 0px;margin-bottom: 8px;margin-left: 5px;display: flex;justify-items: center;align-items: center;">
                      <div style="color: #979797;font-size: 13px;display: flex;justify-content: flex-end;width: 100%;margin-right: 10px;">{{ item.nickname }}</div>
                    </div>
                    <div style="display: flex;justify-content: flex-end;">
                      <div style="display: flex;align-items: flex-end;margin-right: 5px;">
                        <i v-if="item.status=='loading'" style="color: #ECECEC;" class="el-icon-loading"></i>
                        <i v-if="item.status=='fail'" style="color: #F13D50;" class="el-icon-warning"></i>
                      </div>
                      <div @mousedown="handleright($event,item,index,'0')" @contextmenu.prevent style="display: flex;align-items: center;background-color: #1d90f5;border-radius: 20px 3px 20px 20px;cursor: pointer">
                        <div v-if="item.msg.type=='1'" style="margin: 10px 8px 10px 10px;color: #EDEDED;font-size: 15px;max-width: 500px;line-height: 25px;word-break: break-all;word-wrap: break-word;">
                          {{ item.msg.text }}
                        </div>
                      </div>
                      <el-image 
                          @contextmenu.prevent
                          @mousedown="handleright($event,item)"
                          v-if="item.msg.type=='2'"
                          style="width: 150px;max-height: 300px;border-radius: 10px;height: 200px;cursor: pointer;"
                          :src="item.msg.pic_url" 
                          lazy
                          fit="cover"
                          :preview-src-list="[item.msg.pic_url]">
                      </el-image>
                    </div>
                  </div>
                  <el-avatar :size="40" shape='circle' :src="item.user_img">{{ item.nickname }}</el-avatar>
                </div>
              </div>
              <!-- 聊天气泡-自己 -->
                <!-- 聊天气泡-好友 -->
              <div v-if="item.user_id!=user_id&&item.msg.type!='3'&&item.msg.type!='4'" style="width: 100%;display: flex;justify-content: flex-start;margin-bottom: 20px;" :style="{'margin-top':index==0?'20px':'0'}">
                <div style="margin-left: 20px;display: flex;">
                  <el-avatar :size="40" shape='circle' :src="item.user_img">{{ item.nickname }}</el-avatar>
                  <div style="margin-left: 10px;">
                    <div style="margin-bottom: 8px;margin-left: 5px;display: flex;align-items: center;justify-content: flex-start;">
                      <div style="color: #979797;font-size: 13px;display: flex;">{{ item.nickname }}</div>
                      <img v-if="item.authentication!=''&&item.authentication!=null&&item.authentication!='null'" src="../assets/hy.png" style="width: 20px;height: 20px;margin-left: 2px;">
                    </div>
                    <div style="display: flex;">
                      <div @mousedown="handleright($event,item,index,'1')" @contextmenu.prevent style="display: flex;align-items: center;background-color: rgba(56,60,75,0.9);border-radius: 3px 20px 20px 20px;cursor: pointer;">
                        <div v-if="item.msg.type=='1'" style="margin: 10px 8px 10px 11px;color: #EDEDED;font-size: 15px;max-width: 500px;line-height: 25px;word-break: break-all;word-wrap: break-word;">
                          {{ item.msg.text }}
                        </div>
                      </div>
                      <el-image 
                          @contextmenu.prevent
                          v-if="item.msg.type=='2'"
                          style="width: 150px;max-height: 300px;border-radius: 10px;height: 200px;cursor: pointer;"
                          :src="item.msg.pic_url" 
                          lazy
                          fit="cover"
                          :preview-src-list="[item.msg.pic_url]">
                      </el-image>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 聊天气泡-好友 -->
                <!-- 聊天气泡-人工智能 -->
              <div v-if="item.msg.type=='4'" style="width: 100%;display: flex;justify-content: flex-start;margin-bottom: 20px;" :style="{'margin-top':index==0?'20px':'0'}">
                <div style="margin-left: 20px;display: flex;">
                  <el-avatar :size="40" shape='circle' :src="item.user_img">{{ item.nickname }}</el-avatar>
                  <div style="margin-left: 10px;">
                    <div style="margin-bottom: 8px;margin-left: 5px;display: flex;align-items: center;justify-content: flex-start;">
                      <div style="color: #979797;font-size: 13px;display: flex;">{{ item.nickname }}</div>
                      <img  src="../assets/hy.png" style="width: 20px;height: 20px;margin-left: 2px;">
                    </div>
                    <div style="display: flex;">
                      <div @mousedown="handleright($event,item,index,'1')" @contextmenu.prevent style="display: flex;align-items: center;background-color: rgba(56,60,75,0.9);border-radius: 3px 20px 20px 20px;cursor: pointer;">
                        <div v-if="item.msg.type=='4'&&item.msg.pic_url==''" style="white-space: pre-wrap;margin: 10px 8px 10px 11px;color: #EDEDED;font-size: 15px;max-width: 500px;line-height: 25px;word-break: break-all;word-wrap: break-word;">
                          {{ item.msg.text }}
                        </div>
                      </div>
                      <el-image 
                          @contextmenu.prevent
                          v-if="item.msg.type=='4'&&item.msg.pic_url!=''"
                          style="width: 150px;max-height: 300px;border-radius: 10px;height: 200px;cursor: pointer;"
                          :src="item.msg.pic_url" 
                          lazy
                          fit="cover"
                          :preview-src-list="[item.msg.pic_url]">
                      </el-image>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 聊天气泡-人工智能-->
            </div>
          </div>
          <div style="width: 100%;height: 100%;display: flex;justify-content: center;flex: 0.9;">
              <div style="width: 98%;border-radius: 10px;background-color: #424656;display: flex;justify-content: center;align-items: center;margin-bottom: 15px;">
                <div style="flex: 6;margin-left: 10px;margin-top: 5px;margin-bottom: 5px;">
                  <input class="input" type="text" placeholder="善语结善缘，恶语伤人心" v-model="content"  @keyup.enter="send_msg">
                  <!-- <el-input
                    type="textarea"
                    autosize
                    placeholder="请输入内容"
                    v-model="content">
                  </el-input> -->
                </div>
                <div style="flex: 1;display: flex;align-items: center;justify-content: flex-end;">
                  <input type="file" style="display: none" ref="fileInput" @change="handleFileChange">
                  <el-tooltip class="item" effect="dark" content="添加表情" placement="top-start">
                    <img style="width: 23px;height: 23px;margin-right: 15px;cursor: pointer" src="../assets/bq.png" @click="bioaqing">
                  </el-tooltip>
                  <el-tooltip class="item" effect="dark" content="发送图片" placement="top-start">
                    <img style="width: 21px;height: 21px;margin-right: 15px;cursor: pointer" src="../assets/add.png" @click="changeImage">
                  </el-tooltip>
                  <div  @click="send_msg" style="margin-right: 15px;color: #EFEFEF;font-size: 12px;cursor: pointer;
                        background-color: #189DF8;border-radius: 5px;display: flex;justify-content: center;align-items: center;
                        width: 38px;height: 25px;">
                        发送
                  </div>
                </div>
              </div>
          </div>
        </div>
        <div style="flex: 0.8;background: #323644;border-radius: 8px;height: 95%;margin-right: 15px;margin-left: 20px;">
          <div style="margin-left: 20px;margin-top: 10px;font-size: 15px;color: #EBEBEB;">在线人数：{{ onlineuser_list.online_nums }}</div>
          <div v-for="(item,index) in onlineuser_list.user_list" :key="index" style="margin-left: 20px;display: flex;align-items: center;margin-top: 15px;">
            <el-avatar :size="28" shape='circle' :src="item.user_img">头像</el-avatar>
            <div v-if="item.is_online" style="width: 8px;height: 8px;border-radius: 20px;background: #9BF1BD;margin-top: 20px;margin-left: -8px;"></div>
            <div style="display: flex;justify-items: center;margin-left: 10px;">
              <div style="color: #EBEBEB;font-size: 14px">{{ item.nickname }}</div>
            </div>
          </div>
        </div>
       </div>
     </div>
     <div @contextmenu.prevent v-if="emoji.display" style="position: fixed;width: 100%;height: 100%;" @mousedown="gbbq">
      <div :style="{'margin-left':emoji.x,'margin-top':emoji.y,}">
        <VEmojiPicker style="height: 430px;"  @select="onSelectEmoji"/>
      </div>
     </div>
     <div @contextmenu.prevent v-if="suspension.display" style="position: fixed;width: 100%;height: 100%;" @click="suspension.display=0">
      <div style="border-radius: 10px;background: #424656;width: 100px;overflow: hidden;" :style="{'margin-left':suspension.x,'margin-top':suspension.y}">
        <div style="display: flex;align-items: center;padding-top: 10px;padding-left: 10px;cursor: pointer;" @click="fzxx">
          <i class="el-icon-copy-document" style="color: #EFEFEF;font-size: 11px;"></i>
          <div style="font-size: 12px;color: #EFEFEF;margin-left: 5px;">复制</div>
        </div>
        <div v-if="suspension.xsch" style="display: flex;align-items: center;padding-top: 10px;padding-left: 10px;cursor: pointer;" @click="chehui">
          <i class="el-icon-refresh-left" style="color: #EFEFEF;font-size: 12px;margin-top: 1px"></i>
          <div style="font-size: 12px;color: #EFEFEF;margin-left: 5px;">撤回消息</div>
        </div>
        <div style="width: 84px;height: 1px;background-color: #393d4c;margin-left: 8px;margin-top: 8px;"></div>
        <div style="display: flex;align-items: center;padding-top: 5px;padding-left: 10px;cursor: pointer;margin-bottom: 10px;" @click="scxx">
          <i class="el-icon-delete" style="color: #EFEFEF;font-size: 11px;"></i>
          <div style="font-size: 12px;color: #EFEFEF;margin-left: 5px;">删除</div>
        </div>
      </div>
     </div>
    </div>
  </template>
  
<script>
import io from 'socket.io-client'
import Push from 'push.js';
  export default {
    data() {
      return {
        emoji:{
          display:false,
          x:0,
          y:0
        },
        edit_user:{
           pic:"",
           nickname:"",
           formData:[],
           ischoose:false
        },
        dialogVisible: false,
        socket: null,
        input:"",
        userimg:"",
        nickname:"",
        username:"",
        user_id:"",
        content:'',
        onlineuser_list:[],
        chat_list:[],
        now_page:1,
        has_next:true,
        is_msg_loading:false,
        nex_height:0,
        intervalId:0,
        scrollbehavior:'smooth',
        suspension:{
          'x':'',
          'y':'',
          display:false,
        }
      };
    },
    beforeDestroy(){
      //清楚定时器
      clearInterval(this.intervalId);
    },
    created() {
      var nickname = localStorage.getItem("nickname")
      var userimg = localStorage.getItem("user_img")
      var username = localStorage.getItem("username")
      var user_id = localStorage.getItem("user_id")
      this.nickname = nickname
      this.userimg = userimg
      this.username = username
      this.user_id = user_id
      this.rz_login()
      this.get_onlineuser()
      this.init()
      this.get_historychat(this.now_page)
      //清楚定时器
      clearInterval(this.intervalId);
      this.intervalId = setInterval(this.repeat, 20000)
      //获取通知授权
      Push.Permission.request()
    },
    methods: {
      //关闭表情
      gbbq(e){
        if(e.button==2){
            this.emoji.display = false
        }
      },
      //选择表情操作
      onSelectEmoji(emoji){
        this.content += emoji.data
      },
      //选择表情
      bioaqing(e){
        this.emoji.display = true
        this.emoji.x = e.clientX.toString()+'px'
        this.emoji.y = (e.clientY-460).toString()+'px'
      },
      //修改资料
      xiugai(){
        if(this.edit_user.ischoose){
          this.$api.upload(this.edit_user.formData).then(res=>{
              if(res.data.code=='200'){
                this.$api.edituser({
                  username:this.username,
                  user_img:res.data.img_url,
                  nickname:this.edit_user.nickname
                }).then(fh=>{
                  if(fh.data.code=='200'){
                    this.$message.success(fh.data.msg)
                    this.nickname = this.edit_user.nickname
                    this.userimg = res.data.img_url
                    localStorage.setItem('nickname',this.edit_user.nickname)
                    localStorage.setItem('user_img',res.data.img_url)
                    this.dialogVisible = false
                  }else{
                    this.$message.error(fh.data.msg)
                  }
                })
              }else{
                this.$message.error(res.data.msg)
              }
          })
        }else if(this.edit_user.nickname!=this.nickname&&this.edit_user.nickname!=''){
          this.$api.edituser({
              username:this.username,
              user_img:this.userimg,
              nickname:this.edit_user.nickname
            }).then(fh=>{
                if(fh.data.code=='200'){
                  this.$message.success(fh.data.msg)
                  this.nickname = this.edit_user.nickname
                  this.dialogVisible = false
                  this.dialogVisible = false
                  localStorage.setItem('nickname',this.edit_user.nickname)
                }else{
                  this.$message.error(fh.data.msg)
                }
          })
        }
      },
      //加载头像
      load_tx(event){
        var file = event.target.files[0]; // 获取选中的文件
        if (file) {
          if(file.type=='image/png'||file.type=='image/jpg'||file.type=='image/jpeg'||file.type=='image/gif'){
            const fr = new FileReader()
            fr.readAsDataURL(file)
            fr.onload = (e) => {
              this.edit_user.pic = e.target.result
            }
            this.edit_user.formData = new FormData();
            this.edit_user.formData.append('file', file)
            this.edit_user.ischoose = true
          }else{
            this.$message.error('只支持照片格式！')
          }
        }
      },
      //选择头像
      choose_img(){
        this.$refs.imgInput.click()
      },
      //打开修改资料弹窗
      user_edit(){
        this.dialogVisible = true
        this.edit_user.pic = this.userimg
        this.edit_user.nickname = this.nickname
        this.edit_user.ischoose = false
      },
      //复制消息
      fzxx(){
        navigator.clipboard.writeText(this.chat_list[this.suspension.msg_index].msg.text)
        this.$message.success('已复制')
      },
      //删除消息
      scxx(){
        this.$message('不给你删除，嘿嘿')
      },
      //消息撤回
      chehui(){
            var nickname = localStorage.getItem("nickname")
            var userimg = localStorage.getItem("user_img")
            var user_id = localStorage.getItem("user_id")
            var authentication = localStorage.getItem('authentication')
            var msg = {
              'user_id':user_id,
              'nickname':nickname,
              'authentication':authentication,
              'user_img':userimg,
              'status':'loading',
              'msg':{
                'id':this.suspension.msg_id,
                'type':'3',
                'text':nickname+"撤回了一条消息",
                'pic_url':'',
                'send_time':""
              }
            }
            this.socket.emit('message', msg)
      },
      //右击鼠标
      handleright(e,item,index,pd){
        if(e.button==2){
          if(pd=='1'){
            this.suspension.xsch = false
          }else{
            this.suspension.xsch = true
          }
          this.suspension.display = true
          this.suspension.x = e.clientX.toString()+'px'
          this.suspension.y = e.clientY.toString()+'px'
          this.suspension.msg_id = item.msg.id
          this.suspension.msg_index = index
        }
      },
      //文件上传
      handleFileChange(event) {
        var that =this 
        var file = event.target.files[0]; // 获取选中的文件
        if (file) {
          if(file.type=='image/png'||file.type=='image/jpg'||file.type=='image/jpeg'||file.type=='image/gif'){
            var formData = new FormData();
            formData.append('file', file);
            this.$api.upload(formData).then(res=>{
              if(res.data.code=='200'){
                var nickname = localStorage.getItem("nickname")
                var userimg = localStorage.getItem("user_img")
                var user_id = localStorage.getItem("user_id")
                var authentication = localStorage.getItem('authentication')
                var msg = {
                  'user_id':user_id,
                  'nickname':nickname,
                  'authentication':authentication,
                  'user_img':userimg,
                  'status':'loading',
                  'msg':{
                    'id':"",
                    'type':'2',
                    'text':'',
                    'pic_url':res.data.img_url,
                    'send_time':""
                  }
                }
                this.chat_list.push(msg)
                //滚到底部
                that.scrollbehavior = 'smooth'
                that.tobuttom(100)
                //发送消息
                that.chat_list[that.chat_list.length-1].status = 'success'
                that.socket.emit('message', msg)
              }else{
                this.$message.error('图片发送失败')
              }
          })
          }else{
            this.$message.error('只支持照片格式！')
          }
        }
      },
      //选择图片
      changeImage(){
        this.$refs.fileInput.click()
      },
      repeat(){
        this.get_onlineuser()
        this.rz_login()
      },
      //退出登录
      logup(){
        var username = localStorage.getItem("username")
        this.$confirm('小东西不聊了，要跑路啦？', 'RChat 提示', {
          confirmButtonText: '跑路啦',
          cancelButtonText: '再聊会'
        }).then(() => {
          this.$api.unline({'username':username}).then(res =>{
             if(res.data.code=='200'){
                  //断开连接
                  this.beforeDestroy()
                  //清楚定时器
                  clearInterval(this.intervalId);
                  localStorage.removeItem('username')
                  localStorage.removeItem('password')
                  localStorage.removeItem('token')
                  localStorage.removeItem('nickname')
                  localStorage.removeItem('user_img')
                  localStorage.removeItem('user_id')
                  localStorage.removeItem('authentication')
                  this.$router.push({name: "user_login",}, () => {})
             }else{
              this.$message.error('退出失败')
             }
          })
        }).catch(() => {})
      },
      //滚动事件
      handleScroll(e){
        if(e.target.scrollTop==0){
          if(this.has_next){
            this.is_msg_loading = true
            this.$api.getchatlist({'page':this.now_page}).then(res =>{
                this.has_next = res.data.has_next
                this.now_page+=1
                for(let i=0;i<res.data.data.length;i++){
                  this.chat_list.splice(0,0,res.data.data[res.data.data.length-i-1])
                }
                this.is_msg_loading = false
                this.scrollbehavior = 'auto'
                setTimeout(() => {
                  var container = this.$el.querySelector("#chat-content");
                    container.scrollTop = container.scrollHeight - this.nex_height
                    this.nex_height = container.scrollHeight
                }, 0)
            })
          }
        }
      },
      //获取历史消息
      get_historychat(page){
        this.$api.getchatlist({'page':page}).then(res =>{
            this.chat_list = res.data.data
            this.has_next = res.data.has_next
            this.now_page+=1
            this.scrollbehavior = 'auto'
            this.tobuttom(100)
        })
      },
      //获取在线用户
      get_onlineuser(){
        this.$api.online_user().then(res =>{
            this.onlineuser_list = res.data
        })
      },
      //发送消息
      send_msg(){
        if(this.content){
          var that =this
          var nickname = localStorage.getItem("nickname")
          var userimg = localStorage.getItem("user_img")
          var user_id = localStorage.getItem("user_id")
          var authentication = localStorage.getItem('authentication')
          this.chat_list.push({
            'user_id':user_id,
            'nickname':nickname,
            'authentication':authentication,
            'user_img':userimg,
            'status':'loading',
            'msg':{
              'id':"",
              'type':'1',
              'text':that.content,
              'send_time':"",
              'pic_url':""
            }
          })
          //滚到底部
          that.scrollbehavior = 'smooth'
          that.tobuttom(100)
          //发送消息
          that.sendMessage()
          //清空输入框
          that.content = ''
        }
      },
      //滚动到底部
      tobuttom(time){
        //列表滑到底部
        setTimeout(() => {
            var container = this.$el.querySelector("#chat-content")
            container.scrollTop = container.scrollHeight
            this.nex_height = container.scrollHeight
          }, time);
      },
      // 验证登录状态 改变在线状态
      rz_login(){
        var username = localStorage.getItem("username")
        var token = localStorage.getItem('token')
        this.$api.login_verify({
                'user_name': username,
                'token':token
            }).then(res =>{
                if(res.data.code!='200'){
                    this.$message.error('登录校验失败！')
                    localStorage.removeItem('username')
                    localStorage.removeItem('password')
                    localStorage.removeItem('token')
                    localStorage.removeItem('nickname')
                    localStorage.removeItem('user_img')
                    localStorage.removeItem('user_id')
                    localStorage.removeItem('authentication')
                    this.$router.push({name: "user_login",}, () => {})
                }
            })
      },
      init(){
          var that = this
          var user_id = localStorage.getItem("user_id")
          that.socket = io("wss://www.pony7165.top");
          if(!that.socket.connected){
             that.socket.connect()
             that.socket.on('news',(msg)=>{
              if(msg.msg.type == '4'){
                if(msg.msg.text=='正在思考'){
                  that.chat_list[msg.status_id-1].status = 'success'
                  that.chat_list.push(msg)
                  that.tobuttom(500)
                }else{
                  if(msg.msg.pic_url!=''){
                    that.chat_list[msg.status_id].msg.text = ''
                    that.chat_list[msg.status_id].msg.pic_url = msg.msg.pic_url
                    that.tobuttom(500)
                  }else{
                    if(msg.is_end==0){
                      that.chat_list[msg.status_id].msg.text = msg.msg.text
                      that.tobuttom(500)
                    }else if(msg.is_end!=5201314){
                      that.chat_list[msg.status_id].msg.text+=msg.msg.text
                      that.tobuttom(500)
                    }else{
                      that.chat_list[msg.status_id].msg.text+='[结束]'
                    }
                  }
                  
                }
              }
              else if(msg.msg.type == '3'){
                for(let i=0;i<that.chat_list.length;i++){
                  if(that.chat_list[that.chat_list.length-1-i].msg.id==msg.msg.id){
                    that.chat_list[that.chat_list.length-1-i].msg.type = '3'
                    that.chat_list[that.chat_list.length-1-i].msg.text = msg.msg.text
                    break
                  }
                }
                if(msg.user_id != user_id){
                  Push.create(msg.nickname, {
                      body: msg.msg.text,
                      icon: msg.user_img,
                      timeout: 5000,
                      link:'https://www.pony7165.top/#/index_chat',
                      onClick: function () {
                          window.focus();
                          this.close();
                      }
                  })
                }
              }else{
                if(msg.user_id != user_id){
                  that.chat_list.push(msg)
                  that.scrollbehavior = 'smooth'
                  that.tobuttom(500)
                  // 消息推送
                  var msg_text = ''
                  if(msg.msg.type=='2'){
                    msg_text = '[图片]'
                  }else{
                    msg_text = msg.msg.text
                  }
                  Push.create(msg.nickname, {
                      body: msg_text,
                      icon: msg.user_img,
                      timeout: 5000,
                      link:'https://www.pony7165.top/#/index_chat',
                      onClick: function () {
                          window.focus();
                          this.close();
                      }
                  })
                }else{
                  that.chat_list[that.chat_list.length-1]['msg']['id'] = msg.msg.id
                }
              }
             })
             that.socket.on('onlineuser',(res)=>{
              this.onlineuser_list = res.data
             })
            that.socket.on('demo',()=>{

            })
          }
      },
      // 发送消息
      sendMessage() {
          // 检查连接状态
          var nickname = localStorage.getItem("nickname")
          var userimg = localStorage.getItem("user_img")
          var user_id = localStorage.getItem("user_id")
          var authentication = localStorage.getItem('authentication')
          var that = this
          if (that.socket.connected) {
              let send_data = {
                'status_id':that.chat_list.length-1,
                'user_id':user_id,
                'nickname':nickname,
                'authentication':authentication,
                'user_img':userimg,
                'status':'loading',
                'msg':{
                  'id':"",
                  'type':'1',
                  'text':that.content,
                  'pic_url':"",
                  'send_time':""
                }
              }           
              that.socket.emit('message', send_data, (resp) => {
                that.chat_list[Number(resp.split(',')[0])].status = resp.split(',')[1]
              });
          } else {
              // that.socket.connect();
              // that.socket.on('connect', function() {
              //   that.socket.emit('message', localStorage.getItem('username')+": "+that.input, () => {
              //     that.input = '' 
              //   });
              // });
              this.$message('已断开连接！')
          }
      },
      beforeDestroy() {
        // 断开连接
        if (this.socket) {
            const that = this
            that.socket.disconnect();
        }
      }
    }
  }
</script>
  
  
  <style scoped>
    .logup{
      width: 140px;
      height: 30px;
      border-radius: 20px;
      border: 2px solid #898989;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      color: #979797;
      cursor: pointer;
    }
    .main{
      width: 100%;
      height: 100vh;
      background: url(../assets/login_bg.jpg);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .index{
      width: 100%;
      height: 100%;
      position: fixed;
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }
    .window{
      width: 100%;
      height: 100%;
      position: fixed;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .window2{
      width: 70%;
      height: 750px;
      background-color: #272A37;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .input{
    background: none;
    width: 90%;
    height: 100%;
    border-style:none;
    resize: none;
    outline-color: invert;   
    outline-style: none;    
    font-size: 13px; 
    color: aliceblue;
  }
  .chat{
    flex: 4;
    background: #323644;
    border-radius: 8px;
    height: 95%;
    overflow:hidden;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .chat_list{
    flex: 10;
    width: 100%;
    overflow-y: scroll;
  }
  /* 修改滚动条的样式 */
::-webkit-scrollbar {
  width: 4px;
  background-color: #323644;
  cursor: pointer;
}

/* 修改滚动条的拖动手柄样式 */
::-webkit-scrollbar-thumb {
  background-color: #636363;
  border-radius: 5px;
  cursor: pointer;
}
::v-deep .el-dialog{
    border-radius: 10px;
    background-color: #15171C;
}
</style>
  