import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router/index'
import axios from 'axios'
import * as api from './api/api'
import VEmojiPicker from 'v-emoji-picker';

Vue.prototype.$axios = axios
Vue.prototype.$api = api
Vue.use(VEmojiPicker)


Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  render: h => h(App),
  router
}).$mount('#app')
