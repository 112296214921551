//1.导入VueRouter
import Vue from "vue";
import VueRouter from 'vue-router'
import user_login from '../views/user_login.vue'
import index_chat from "@/views/index_chat.vue";
import user_register from "@/views/user_register.vue";
//2.使用路由
Vue.use(VueRouter);
//3.创建VueRouter的实例
const router = new VueRouter({
    //tips:不想要 #（锚点）就添加下面代码
     mode:'hash', 
    //4.配置路由信息
    routes :[
        {
          path: "/user_login",
          name:'user_login',
          component:user_login
        },
        {
          path: "/index_chat",
          name:'index_chat',
          component:index_chat
        },
        {
          path: "/user_register",
          name:'user_register',
          component:user_register
        }
      ]
})
//5.导出路由实例
export default router