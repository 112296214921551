import axios from 'axios'

var api = 'https://www.pony7165.top'

// 登录接口
export function Login(params){
  return axios({
    url:api+"/login",
    data:params,
    method:"post",
    headers:{
      'Content-Type': 'application/x-www-form-urlencoded'
    }
})
}

// 登录校验
export function login_verify(params){
  return axios({
    url:api+"/login_verify",
    data:params,
    method:"post",
    headers:{
      'Content-Type': 'application/x-www-form-urlencoded'
    }
})
}

// 在线用户
export function online_user(){
  return axios({
    url:api+"/online_user",
    method:"get",
    headers:{
      'Content-Type': 'application/x-www-form-urlencoded'
    }
})
}

// 获取历史记录
export function getchatlist(params){
  return axios({
    url:api+"/getchatlist",
    data:params,
    method:"post",
    headers:{
      'Content-Type': 'application/x-www-form-urlencoded'
    }
})
}

// 用户离线
export function unline(params){
  return axios({
    url:api+"/unline",
    data:params,
    method:"post",
    headers:{
      'Content-Type': 'application/x-www-form-urlencoded'
    }
})
}

// 文件上传
export function upload(formData){
  return axios({
    url:api+"/upload",
    data:formData,
    method:"post",
    headers:{
      'Content-Type': 'multipart/form-data'
    }
})
}


// 获取验证码
export function signcode(formData){
  return axios({
    url:api+"/signcode",
    data:formData,
    method:"post",
    headers:{
      'Content-Type': 'multipart/form-data'
    }
})
}


// 注册
export function register(formData){
  return axios({
    url:api+"/register",
    data:formData,
    method:"post",
    headers:{
      'Content-Type': 'multipart/form-data'
    }
})
}


// 修改资料
export function edituser(formData){
  return axios({
    url:api+"/edituser",
    data:formData,
    method:"post",
    headers:{
      'Content-Type': 'multipart/form-data'
    }
})
}























